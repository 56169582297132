/* App.css */

.App {
  text-align: center;
}

.app-container {
  background-color: lightskyblue;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh; /* Adjusted for the header */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; /* Added padding */
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger-menu {
  position: absolute;
  top: 60px; /* Adjusted for the header height */
  right: 20px;
  background-color: #282c34;
  padding: 10px;
  border-radius: 5px;
  display: none;
}

.hamburger-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hamburger-menu ul li {
  margin-bottom: 10px;
}

.hamburger-menu ul li a {
  color: white;
  text-decoration: none;
}
