
  .menu-icon:hover {
    filter: drop-shadow(0 0 5px yellow); /* Adjust the shadow color and size as needed */
  }


  .link {
    white-space: normal; /* Allow text to wrap */
    max-width: 150px; /* Adjust max-width as needed */
  }


  .hamburger-menu {
    position: absolute;
    top: 60px; /* Adjust as needed */
    right: 20px; /* Adjust the distance from the right side */
    width: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
    animation: slideIn 0.3s ease-in-out;
  }
    
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    width: 30%;
  }
  
  .footer-section h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .social-icons img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
  }
  



  
  .website-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .website-item {
    border: 1px solid #ccc; /* Add border */
    padding: 20px; /* Add padding */
    margin: 10px; /* Add margin */
    background-color: #f9f9f9; /* Add background color */
    max-width: 300px; /* Optional: set maximum width */
  }
  
  .website-item h3 {
    margin-top: 0; /* Reset margin */
  }
  
  .website-item img {
    max-width: 100%; /* Make images responsive */
  }
  
  .website-item p {
    margin-bottom: 10px; /* Add spacing between paragraphs */
  }
  
  .website-item a {
    display: block; /* Make links full width */
    color: #007bff; /* Change link color */
    text-decoration: none; /* Remove default underline */
    text-align: center;

  }
  
  .website-item a:hover {
    text-decoration: underline; /* Add underline on hover */
  }


  @media screen and (max-width: 1200px) {
    .website-item {
      flex: 1 0 calc(50% - 20px); /* Two wide at medium screens */
    }
  }
  
  @media screen and (max-width: 768px) {
    .website-item {
      flex: 1 0 calc(100% - 20px); /* One wide at small screens */
    }
  }

  @media (max-width: 768px) {
    .custom-slider {
      grid-template-columns: 1fr;
    }
  }

  
  .slider-item {
    text-align: center;
  }
  
  .slider-item img {
    max-width: 100%;
    height: auto;
  }
  
  .slider-use-description {
    text-align: center;
    margin-top: 20px;
    color: #666;
  }



  @media (max-width: 768px) {
    .site-display {
      grid-template-columns: 1fr;
    }
  }
  
  .site-display {
    text-align: center;
  }
  
  .site-display img {
    max-width: 100%;
    height: auto;
  }
  
  .site-display-use-description {
    text-align: center;
    margin-top: 20px;
    color: #666;
  }
  